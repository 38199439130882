import { useQuery } from '@tanstack/react-query'
import { useAccount } from 'wagmi'
import fetcher from '@/utils/fetcher'
import isLoggedIn from '@/utils/isLoggedIn'
import type { ConnectWalletData } from '@/api/connect-wallet'

export type GetUserData = Omit<ConnectWalletData, 'authorization'>

export default function getUser() {
  return fetcher<GetUserData>('/get-current-login-user', {
    method: 'GET',
    disabledErrorToast: true,
  })
}

export function useUserInfo() {
  const { isConnected, address } = useAccount()

  return useQuery({
    queryKey: ['get-current-login-user'],
    queryFn: getUser,
    enabled: () => isLoggedIn(),
    select: (data) => {
      return isConnected && data.ethAddress.toLowerCase() === address?.toLowerCase() ? data : undefined
    },
  })
}
