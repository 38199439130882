import { merge } from 'lodash-es'
import { AUTH_OBJECT_KEY } from '@/constants'

type AuthObject = Record<
  /** address */
  string,
  | undefined
  | {
      authorization?: string
      signature?: string
      message?: string
    }
>

export function getAuthObject(): AuthObject {
  const authObjectString = window.localStorage.getItem(AUTH_OBJECT_KEY)

  if (!authObjectString) return {}

  try {
    return JSON.parse(authObjectString)
  } catch {
    return {}
  }
}

export function setAuthObject(authObject: AuthObject) {
  const oldAuthObject = getAuthObject()
  const newAuthObject = merge(oldAuthObject, authObject)
  window.localStorage.setItem(AUTH_OBJECT_KEY, JSON.stringify(newAuthObject))
}
