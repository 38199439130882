import { useQueryClient } from '@tanstack/react-query'
import { useSignMessage } from 'wagmi'
import { useCallback } from 'react'
import { connectWallet } from '@/api'
import { getAuthObject, setAuthObject } from '@/utils/authObject'
import type { Connector } from 'wagmi'
import type { Hex } from 'viem'
import type { ConnectWalletData } from '@/api'

export default function useConnectApiLogic() {
  const { signMessageAsync } = useSignMessage()
  const queryClient = useQueryClient()

  const connectFn = useCallback(
    async (options: {
      account: string
      toggleWalletModal?: () => void
      setPendingWallet?: (connector: Connector | undefined) => void
    }) => {
      const { account, toggleWalletModal, setPendingWallet } = options

      try {
        const authObject = getAuthObject()
        const m = authObject[account]?.message
        const s = authObject[account]?.signature

        let connectWalletResponse: string | ConnectWalletData | false = false
        if (m && s) {
          connectWalletResponse = await connectWallet({
            address: account,
            signature: s,
            message: m,
            disabledErrorToast: true,
          }).catch((err) => {
            console.error(err)
            return false
          })
        }

        if (!connectWalletResponse) {
          connectWalletResponse = await connectWallet({
            address: account,
          })
        }

        let authorization: string | undefined
        let signature: string | undefined = s
        let message: string | undefined = m
        if (typeof connectWalletResponse === 'string') {
          message = connectWalletResponse
          signature = await signMessageAsync({
            message,
            account: account as Hex,
          })
          const connectWalletResponse2 = await connectWallet({
            address: account,
            signature,
            message,
          })
          if (typeof connectWalletResponse2 === 'string') {
            setPendingWallet?.(undefined)
            return
          }
          authorization = connectWalletResponse2.authorization
        } else {
          authorization = connectWalletResponse.authorization
        }
        setAuthObject({
          [account]: {
            authorization,
            signature,
            message,
          },
        })
        await Promise.all([
          queryClient.fetchQuery({
            queryKey: ['get-current-login-user'],
          }),
          queryClient.fetchQuery({
            queryKey: ['get-point-gold-info'],
          }),
        ])
        setPendingWallet?.(undefined)
        toggleWalletModal?.()
      } catch (error) {
        console.log('%c [ error ]-93', 'font-size:13px; background:pink; color:#bf2c9f;', error)
        setPendingWallet?.(undefined)
      }
    },
    [queryClient, signMessageAsync],
  )

  return { connectFn }
}
