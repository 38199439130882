import { disconnect, getAccount } from 'wagmi/actions'
import wagmiConfig from '@/constants/wagmiConfig'
import { API_BASE } from '@/constants'
import { getAuthObject, setAuthObject } from '@/utils/authObject'

export default function disconnectWallet() {
  const { address } = getAccount(wagmiConfig)
  disconnect(wagmiConfig).catch((err) => {
    console.error('Failed to disconnect', err)
  })

  let authorization = ''
  if (address) {
    authorization = getAuthObject()[address]?.authorization || ''
    setAuthObject({ [address]: { authorization: '' } })
  }
  if (!authorization) return

  return fetch(`${API_BASE}/disconnect-wallet`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorization,
    },
  })
    .catch(() => {})
    .finally(() => {
      if (['/', '/earn'].indexOf(window.location.pathname) === -1) {
        window.location.replace('/')
      }
    })
}
